import axios from 'axios';
import qs from 'qs';
import {initCarGallerySliders} from '../../components/molecules/car-gallery-slider/carGallerySlider';
import '../../components/organisms/stock-car/stockCar';
import {StockCar} from '../../components/organisms/stock-car/stockCar';
import {ScrollToSection} from '../../../../assets/private/js/scrollToSection';
import {initTooltips} from '../../components/atoms/tooltip/tooltip';
import {initFullSizeGallery} from '../../components/molecules/full-size-gallery/fullSizeGallery';
import {initStockCarsSliders} from '../../components/organisms/stock-cars-slider/stockCarsSlider';
import {selects} from '../../components/atoms/select/select-multi';
import {reinitInputs, SetableInput} from '../../components/atoms/input-range/input-range';

class Stock {
    constructor(element) {
        this.element = element;
        this.form = element.querySelector('.js-stock__form');
        this.formFields = [];
        this.getFormFields();
        this.filterChips = Array.from(this.element.querySelectorAll('.js-stock__filter-chips'));
        this.additionalFilters = Array.from(this.element.querySelectorAll('.js-stock__additional-filter'));

        this.initToggleMoreFilters();

        this.form.addEventListener('submit', (event) => this.submit(event));
        
        this.pagination = new StockPagination(this.element);
        let url = window.location.href.split('#');
      
        let query = {};
      
        if (url[1]) {
            let params = url[1].split('/');
            
            for (const  elem of params) {
                let temp = elem.split('=');
                query[temp[0]] = temp[1];
                
                switch(temp[0]) {
                    
                    case 'location':
                      
                        let location = temp[1].split(',');                
                        console.log(location);
                        for (const c of location) {
                          //  alert(decodeURI(c).replace(/%20/g,' '));
                            $('li[data-value="'+decodeURI(c).replace(/%20/g,' ')+'"]').trigger('click');
                        //    $('select[name="model[]"]').change();
                        }
                    break;
                    case 'model':
                        let cars = temp[1].split(',');                        
                        for (const c of cars) {
                            $('li[data-value="'+c.replace(/%20/g,' ')+'"]').trigger('click');
                        //    $('select[name="model[]"]').change();
                        }
                    break;
                    case 'engine':
                        let engine = temp[1].split(',');                        
                        for (const e of engine) {                           
                            $('li[data-value="'+e.replace(/%20/g,' ')+'"]').trigger('click');
                        //    $('select[name="model[]"]').change();
                        }
                    break;
                    case 'color':
                        let colors = temp[1].split(',');                        
                        for (const c of colors) {                           
                           $('li[data-value="'+c.replace(/%20/g,' ')+'"]').trigger('click');
                        //    $('select[name="model[]"]').change();
                        }
                    break;
                    case 'max-power':
                        let maxPower = temp[1].split(',');                        
                        for (const c of maxPower) {                           
                           $('li[data-value="'+c.replace(/%20/g,' ')+'"]').trigger('click');
                        //    $('select[name="model[]"]').change();
                        }
                    break;
                    case 'version':
                        let version = temp[1].split(',');                        
                        for (const c of version) {                           
                           $('li[data-value="'+c.replace(/%20/g,' ')+'"]').trigger('click');
                        //    $('select[name="model[]"]').change();
                        }
                    break;
                    case 'production-year':
                        let productionYear = temp[1].split(',');                        
                        for (const c of productionYear) {                           
                           $('li[data-value="'+c.replace(/%20/g,' ')+'"]').trigger('click');
                        //    $('select[name="model[]"]').change();
                        }
                    break;
                    case 'showroom':
                        let showroom = temp[1].split(',');                        
                        for (const c of showroom) {                           
                           $('input[value="'+c.replace(/%20/g,' ')+'"]').trigger('click');
                        //    $('select[name="model[]"]').change();
                        }
                    break;
                    case 'discount-price-max':                       
                        $('#price-range-max').val(temp[1])
                    break;
                    case 'discount-price-min':
                        $('#price-range-min').val(temp[1])
                    break;
                    case 'car_type':
                        $('input[value="'+temp[1]+'"]').trigger('click');
                    break;

                }



            }
            $('.content__submit button[type="submit"]').click();
        }
        this.intersectionObserver = new StockIntersectionObserver(this.element.querySelector('.js-stock__intersection-observer'));

        this.filterFields = Array.from(this.form.querySelectorAll('.js-stock__form-field'));
        this.selectedFiltersElement = this.form.querySelector('.js-stock__selected-filters-inner');

        this.filterChips.forEach(filterChipsElement => {
            filterChipsElement.querySelector('.js-stock__filter-chips__button').addEventListener('click', () => this.handleFilterChipsClick(filterChipsElement));
        });

        this.filterFields.forEach(field => {
            field.addEventListener('field-change', (e) => this.handleFilterFieldChange(e));
        });

        this.priceRange = this.element.querySelector('.js-input-range');

        this.elementMin = new SetableInput(this.element.querySelector('.js-input-range__min'));
        this.elementMax = new SetableInput(this.element.querySelector('.js-input-range__max'));

        this.priceRange.addEventListener('range-change', (e) => this.handlePriceRangeChange(e));


      //  this.clearFiltersButton = this.element.querySelector('.js-stock__clear-filters-button');
      //  this.clearFiltersButton.addEventListener('click', () => this.clearAllFilters());

        this.activeFiltersLabel = this.element.querySelector('.js-stock__selected-filters-label');
    }

    handlePriceRangeChange(e) {
        let priceRangeChips = this.createPriceRangeFilterChipsIfNotExists('price-range', {
            value: e.detail.min + '-' + e.detail.max,
            label: this.formatCurrency(e.detail.min) + ' - ' + this.formatCurrency(e.detail.max),
        });

        if (priceRangeChips) {
            priceRangeChips.querySelector('.js-stock__filter-chips__button').addEventListener('click', () => this.handlePriceRangeChipsClick());
        }

        priceRangeChips = this.selectedFiltersElement.querySelector(`.js-stock__filter-chips[data-select="price-range"]`);

        const min = parseInt(this.priceRange.dataset.min);
        const max = parseInt(this.priceRange.dataset.max);

        if (priceRangeChips && parseInt(e.detail.min) === min && parseInt(e.detail.max) === max) {
            priceRangeChips.parentNode.removeChild(priceRangeChips);
        }

        this.toggleResetFiltersButton();
    }

    handlePriceRangeChipsClick() {
        const min = this.priceRange.dataset.min;
        const max = this.priceRange.dataset.max;
        this.elementMin.setValue(min);
        this.elementMax.setValue(max);
        this.priceRange.dataset.selectedMin = min;
        this.priceRange.dataset.selectedMax = max;
        reinitInputs();
    }

    toggleResetFiltersButton() {
        const chipsElements = Array.from(this.element.querySelectorAll('.js-stock__filter-chips'));

        if (chipsElements.length > 0) {
            this.clearFiltersButton.classList.add('is-active');
            this.activeFiltersLabel.classList.add('is-active');
        } else if (chipsElements.length <= 0) {
            this.clearFiltersButton.classList.remove('is-active');
            this.activeFiltersLabel.classList.remove('is-active');
        }
    }

    clearAllFilters() {
        Array.from(this.element.querySelectorAll('.js-stock__filter-chips')).forEach(chips => {
            this.simulateClick(chips.querySelector('.js-stock__filter-chips__button'));
        });
    }
    generateLinks() {
        let searchParams = new URLSearchParams(window.location.search)
        var ePath = '';
        var x=0;
        searchParams.forEach(function(index,value) {
            if (x>0) {
                ePath += '&'+value+'='+index;
            } else {
                ePath += '?'+value+'='+index;
            }
            //console.log(ePath);

            x++;
        })
        $('.cars__list-item').each(function() {
                $(this).find('.a-button--secondary').attr('href',$(this).find('.a-button--secondary').attr('href')+ePath);
        })
    }
    formatCurrency(string) {
        return string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' zł';
    }

    handleFilterFieldChange(e) {
        const select = e.target.dataset.fieldName;
        const activeFilters = e.detail.value;
        const chipsElements = Array.from(this.selectedFiltersElement.querySelectorAll(`.js-stock__filter-chips[data-select="${select}"]`));

        activeFilters.forEach(filter => {
            const newChipsElement = this.createChipsIfNotExists(select, filter);
            if (newChipsElement) {
                newChipsElement.querySelector('.js-stock__filter-chips__button').addEventListener('click', () => this.handleFilterChipsClick(newChipsElement));
            }
        });

        chipsElements.forEach(chipsElement => {
            const chipsElementSelect = chipsElement.dataset.select;
            const chipsElementValue = chipsElement.dataset.value;

            if (chipsElementSelect === select) {
                let isActive = false;
                activeFilters.forEach(filter => {
                    if (filter.value === chipsElementValue) {
                        isActive = true;
                    }
                });

                if (!isActive) {
                    chipsElement.parentNode.removeChild(chipsElement);
                }
            }
        });
        this.toggleResetFiltersButton();
    }

    createChipsIfNotExists(select, filter) {
        const chipsElement = this.selectedFiltersElement.querySelector(`.js-stock__filter-chips[data-select="${select}"][data-value="${filter.value}"]`);
        if (!chipsElement) {
            const newChipsElement = this.createChipsElement(select, filter);

            this.selectedFiltersElement.appendChild(newChipsElement);

            return newChipsElement;
        }

        return null;
    }

    createPriceRangeFilterChipsIfNotExists(select, filter) {
        const chipsElement = this.selectedFiltersElement.querySelector(`.js-stock__filter-chips[data-select="${select}"]`);

        if (!chipsElement) {
            const chips = this.createChipsElement('price-range', filter);
            this.selectedFiltersElement.appendChild(chips);
            return chips;
        } else {
            chipsElement.querySelector('.js-stock__filter-chips__label').dataset.value = filter.value;
            chipsElement.querySelector('.js-stock__filter-chips__label').innerHTML = filter.label;
        }

        return null;
    }

    createChipsElement(select, filter) {
        const element = document.createElement('div');
        element.innerHTML = `
                <span class="selected-filters__filter-chips js-stock__filter-chips a-filter-chips" data-select="${select}" data-value="${filter.value}">
                    <span class="js-stock__filter-chips__label a-filter-chips__label">${filter.label}</span>
                    <button type="button" class="js-stock__filter-chips__button a-filter-chips__button" aria-label="usuń"></button>
                </span>
            `.trim();

        return element.firstChild;
    }

    handleFilterChipsClick(chips) {
        if(chips.dataset.select === 'price-range') {
            this.handlePriceRangeChipsClick();
        } else {
            const select = selects[chips.dataset.select];
            const option = select.el.querySelector(`.js-select-multi__item[data-value="${chips.dataset.value}"]`);
            this.simulateClick(option);
        }
    }

    simulateClick(element) {
        const event = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
        });
        element.dispatchEvent(event);
    };

    getFormFields() {
        const inputs = this.form.querySelectorAll('input, select');
        inputs.forEach(item => {
            if (!this.formFields.includes('s_' + item.name)) {
                this.formFields.push('s_' + item.name);
            }
        });
    }

    fadeIn(el, display){
        el.style.opacity = 0;
        el.style.display = display || "block";

        (function fade() {
            var val = parseFloat(el.style.opacity);
            if (!((val += .1) > 1)) {
                el.style.opacity = val;
                requestAnimationFrame(fade);
            }
        })();
    }

    fadeOut(el){
        el.style.opacity = 1;

        (function fade() {
            if ((el.style.opacity -= .1) < 0) {
                el.style.display = "none";
            } else {
                requestAnimationFrame(fade);
            }
        })();
    }

    initToggleMoreFilters() {
        const moreFiltersButton = this.element.querySelector('.js-stock__filters-more-button');

        moreFiltersButton.addEventListener('click', () => {
            if(moreFiltersButton.classList.contains('is-active')) {
                moreFiltersButton.classList.remove('is-active');
            } else {
                moreFiltersButton.classList.add('is-active');
            }

            if (moreFiltersButton.classList.contains('is-active')) {
                moreFiltersButton.textContent = moreFiltersButton.dataset.less;
            } else {
                moreFiltersButton.textContent = moreFiltersButton.dataset.more;
            }

            this.additionalFilters.forEach(filter => {
                if(moreFiltersButton.classList.contains('is-active')) {
                    this.fadeIn(filter, 'block');
                } else {
                    this.fadeOut(filter, 'block');
                }
            });
        });
    }

    mutateUrl(filters) {
        const esc = encodeURIComponent;
        let query = {};
        for (const [key, value] of new URLSearchParams(window.location.search).entries()) {
            if (!this.formFields.includes(key)) {
                query[key] = value;
            }
        }
        query = Object.assign(query, filters);

       
        let queryStringData = window.location.search;
        let urlParams = new URLSearchParams(queryStringData);
        let x = 0;
        let additionalString = '';
        for (const param of urlParams) {
            if (x==0) {
                additionalString += '?';
            } else {
                additionalString += '&';
            }
            additionalString += param[0]+'='+param[1];
            x++;
          }
          

        let queryString = [];
        for (const [queryKey, queryValue] of Object.entries(query)) {
            
            
             if (!['action', 'resetPagination', 'page'].includes(queryKey)) {
                 if (Array.isArray(queryValue)) {
                    if (queryValue.length > 0) {
                         let temp = queryKey+'=';
                         let counter = queryValue.length;
                         let i = 0;
                         queryValue.forEach(value => {
                            if (queryValue) {
                            temp += esc(value)+(i + 1 == counter ? '' : ',');
                            i++;
                            }
                        
                        //  queryString.push('s_' + esc(queryKey) + '[]=' + esc(value))
                     })
                     queryString.push(temp);
                    }
                    
                 } else {
                    if (queryValue) {
                     queryString.push(esc(queryKey) + '=' + esc(queryValue))
                    }
                 }
             }
        }
        console.log(queryString);
        const url = window.location.origin + window.location.pathname + additionalString + '#' + queryString.join('/');
        history.pushState({}, document.title, url);
    }

    submit(event) {
        event.preventDefault();

        const stockCarsWrapper = document.querySelector('.js-stock__cars-wrapper');
        stockCarsWrapper.classList.add('is-loading');

        const filters = {
            'action': 'searchFilter',
        };
        const formData = new FormData(event.target);
        Array.from(formData.keys())
            .forEach(key => {
                if (key.includes('[]')) {
                    filters[key.slice(0, key.length - 2)] = formData.getAll(key);
                } else if (['discount-price-min', 'discount-price-max'].includes(key)) {
                    filters[key] = formData.get(key).replace(/\D/g, '');
                } else if (!key.endsWith('_search')) {
                    filters[key] = formData.get(key);
                }
            });

        if (formData.get('resetPagination') === '1') {
            filters['page'] = '1';
        }

        this.mutateUrl(filters);

        axios({
            method: 'POST',
            headers: {'content-type': 'application/x-www-form-urlencoded'},
            data: qs.stringify(filters),
            url: '/wp/wp-admin/admin-ajax.php',
        }).then(response => {
            stockCarsWrapper.innerHTML = response.data;
            initCarGallerySliders();
            initTooltips();
            initFullSizeGallery();
            initStockCarsSliders();
            window.lazyLoading.updateLazyLoading();
            this.pagination = new StockPagination(this.element);
            this.intersectionObserver = new StockIntersectionObserver(this.element.querySelector('.js-stock__intersection-observer'));
            const stockCars = Array.from(document.querySelectorAll('.js-stock-car')).map(item => new StockCar(item));
            this.generateLinks();
            stockCarsWrapper.classList.remove('is-loading');
            ScrollToSection.scroll(stockCarsWrapper.offsetTop - 32);
        }).catch(error => {
            console.error(error);
            stockCarsWrapper.classList.remove('is-loading');
        });
    }
}

class StockPagination {
    constructor(parent) {
        this.pagination = parent.querySelector('.js-stock__pagination');
        this.paginationItems = Array.from(this.pagination.querySelectorAll('.js-stock__pagination-item'));
        this.paginationItems.forEach(item => {
            item.addEventListener('click', () => this.paginationChanged(item.dataset.paginationNumber));
        });
    }

    paginationChanged(pageNumber) {
        this.pagination.querySelector('.js-stock__currentPage').value = pageNumber;
        this.pagination.querySelector('.js-stock__resetPagination').value = 0;
    }
}

class StockIntersectionObserver {
    constructor(element) {
        this.element = element;
        this.lastViewedCarField = document.querySelector('.js-form .js-form__last-viewed-car-field');
        this.showroomIdField = document.querySelector('.js-form .js-form__showroomId-field');
        this.dealerName = document.querySelector('.js-form__dealer-name');

        const carsObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if(entry.isIntersecting) {
                        this.lastViewedCarField.value = `${entry.target.dataset.modelName} Cena: ${entry.target.dataset.price}`;
                        if (entry.target.dataset.showroomId) {
                            this.showroomIdField.value = entry.target.dataset.showroomId;
                        } else {
                            this.showroomIdField.value = '';
                        }
                        if (entry.target.dataset.dealer) {
                            this.dealerName.innerHTML = entry.target.dataset.dealer;
                        } else {
                            this.dealerName.innerHTML = this.dealerName.dataset.initialValue;
                        }
                    }
                });
            },
            {
                threshold: [0],
                rootMargin: '-50% 0px'
            }
        );

        this.stockCars = Array.from(document.querySelectorAll('.js-stock-car'));

        this.stockCars.forEach(car => {
            carsObserver.observe(car);
        });

    }
}


document.addEventListener('DOMContentLoaded', () => {
    const stockElement = document.querySelector('.js-stock');
    if (stockElement) {
        new Stock(document.querySelector('.js-stock'));
    }
    const stockCars = Array.from(document.querySelectorAll('.js-stock-car')).map(item => new StockCar(item));
});