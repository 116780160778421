import favicon16 from '../img/favicon-v2/favicon-16x16.v2.png';
import faviconSVG16 from '../img/favicon-v2/favicon-16x16.v2.svg';
import favicon32 from '../img/favicon-v2/favicon-32x32.v2.png';
import favicon180 from '../img/favicon-v2/favicon-180x180.v2.png';
import favicon192 from '../img/favicon-v2/favicon-192x192.v2.png';

import volvoLogo from '../img/volvo-logo.svg';
import errorIcon from '../img/errorIcon.svg';
import warningIcon from '../img/warningIcon.svg';
import successIcon from '../img/successIcon.svg';

import notFoundImg from '../img/not-found-img.png';

import siteHeading from '../img/acf-blocks-previews/siteHeading.png';
import textEditor from '../img/acf-blocks-previews/textEditor.png';
import textEditorExtended from '../img/acf-blocks-previews/textEditorExtended.png';

import {importAll} from "./importAll";

const volvoIcons = importAll(require.context('../img/volvo-icons', false, /\.(png|jpe?g|svg)$/));

import './lazyloading';
import './scrollToSection';
import '../../../includes/views/components/atoms/tooltip/tooltip';
import '../../../includes/views/components/atoms/checkbox/checkbox';
import '../../../includes/views/components/atoms/input/input';
import '../../../includes/views/components/atoms/input-range/input-range';
import '../../../includes/views/components/atoms/select/select-multi';
import '../../../includes/views/components/atoms/textarea/textarea';


import '../../../includes/views/components/molecules/car-gallery-slider/carGallerySlider';
import '../../../includes/views/components/molecules/full-size-gallery/fullSizeGallery';

import '../../../includes/views/components/organisms/accordion-section/accordionSection';
import '../../../includes/views/components/organisms/cookies/cookies';
import '../../../includes/views/components/organisms/form/form';
import '../../../includes/views/components/organisms/stock-cars-slider/stockCarsSlider';

import '../../../includes/views/layouts/stock/stock';
import '../../../includes/views/layouts/stock-car-single/stockCarSingle';
import '../../../includes/views/layouts/side-form/sideForm';
